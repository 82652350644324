import { CartSkuApiDto, OfferCampaignApiDto } from '@b2x/storefront-api-js-client/src';

import { CartCampaignMultiOffers } from './CartCampaignMultiOffers';
import { CartCampaignSingleOfferBuyXGetYFlattenedSkus } from './CartCampaignSingleOfferBuyXGetYFlattenedSkus';
import { Div } from './HTMLElement';

export interface CartCampaignsProps {
  campaigns: Array<OfferCampaignApiDto>;
  campaignsCartSkus: Partial<Record<string, Array<CartSkuApiDto>>> | undefined;
}

export const CartCampaigns = ({ campaigns, campaignsCartSkus }: CartCampaignsProps) => {
  if (campaigns.length === 0) {
    return null;
  }

  return (
    <Div className="cart-campaigns" display="flex" flexDirection="column" gap={3} marginBottom={3}>
      {campaigns.map((campaign) =>
        campaign.offers?.length === 1 ? (
          campaign.offers.at(0)?.promotionType === 'BUYXGETY' ? (
            <CartCampaignSingleOfferBuyXGetYFlattenedSkus
              campaign={campaign}
              campaignsCartSkus={campaignsCartSkus}
              key={campaign.id}
            />
          ) : undefined
        ) : (
          <CartCampaignMultiOffers campaign={campaign} key={campaign.id} />
        )
      )}
    </Div>
  );
};
