import './DoubleColumnsA.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from '../Container';

export interface DoubleColumnsAContentType {
  body?: {
    doubleColumns?: b2x.contentTypes.ContentSectionList<{
      asset?: b2x.contentTypes.AssetContentSectionV2;
      copy?: {
        content?: string;
        cta?: b2x.contentTypes.CtaContentSection;
        options?: {
          background?: string;
          classNames?: string;
          size?: string;
          textAlign?: string;
          textAlignMobile?: string;
        };
        pretitle?: string;
        subtitle?: string;
        title?: string;
      };
      options?: {
        background?: string;
        classNames?: string;
        ordering?: {
          desktop?: string;
          mobile?: string;
        };
      };
    }>;
  };
  head?: {
    content?: string;
    title?: string;
  };
  options?: {
    background?: string;
  };
}

interface DoubleColumnsAProps extends DoubleColumnsAContentType {
  mainComponent?: boolean;
}

export const DoubleColumnsA = ({ body, head, mainComponent, options }: DoubleColumnsAProps) => {
  const { getPagePath } = b2x.useAppStaticContext();

  return (
    <b2x.Div
      background={options?.background}
      className="double-columns-a"
      paddingY={options?.background ? { lg: 5, xs: 3 } : undefined}
    >
      <Container>
        {(head?.title || head?.content) && (
          <b2x.Div className="component-head">
            <b2x.Row justifyContent={'center'}>
              <b2x.Col size={{ lg: 10, xs: 12 }}>
                <b2x.ConditionalWrapper
                  condition={head.title !== undefined}
                  wrapper={mainComponent ? <b2x.H1 className="title" /> : <b2x.P className="title" />}
                >
                  {b2x.formatHtml(head.title)}
                </b2x.ConditionalWrapper>
                {head.content && <b2x.Div className="content">{b2x.formatHtml(head.content)}</b2x.Div>}
              </b2x.Col>
            </b2x.Row>
          </b2x.Div>
        )}
        <b2x.Div className="component-body">
          {body?.doubleColumns?.map((doubleColumn) => {
            const copyColumnSize: b2x.ColSize =
              doubleColumn.copy?.options?.size === 'small'
                ? 4
                : doubleColumn.copy?.options?.size === 'medium'
                ? 6
                : doubleColumn.copy?.options?.size === 'large'
                ? 8
                : 6;

            const desktopBehavior = `desktop-${doubleColumn.options?.ordering?.desktop?.replace(' ', '-')}`;
            const mobileBehavior = `mobile-${doubleColumn.options?.ordering?.mobile?.replace(' ', '-')}`;

            const textAlign: b2x.TextAlign =
              doubleColumn.copy?.options?.textAlign === 'start'
                ? 'start'
                : doubleColumn.copy?.options?.textAlign === 'center'
                ? 'center'
                : doubleColumn.copy?.options?.textAlign === 'end'
                ? 'end'
                : 'start';

            const textAlignMobile: b2x.TextAlign =
              doubleColumn.copy?.options?.textAlignMobile === 'start'
                ? 'start'
                : doubleColumn.copy?.options?.textAlignMobile === 'center'
                ? 'center'
                : doubleColumn.copy?.options?.textAlignMobile === 'end'
                ? 'end'
                : 'start';

            return (
              <b2x.Div
                background={doubleColumn.options?.background}
                className={classnames('double-column-a', doubleColumn.options?.classNames)}
                key={doubleColumn.contentSectionId}
                marginBottom={undefined}
                paddingY={{ lg: 5 }}
              >
                <b2x.Div className={classnames(desktopBehavior, mobileBehavior)}>
                  <b2x.Row>
                    <b2x.Col
                      className={classnames('copy-column', doubleColumn.copy?.options?.classNames)}
                      display={{ lg: 'flex', xs: 'block' }}
                      size={{ lg: copyColumnSize, xs: 12 }}
                    >
                      <b2x.Div
                        alignItems={'center'}
                        background={doubleColumn.copy?.options?.background}
                        display={{ lg: 'flex', xs: 'block' }}
                        paddingX={{ lg: 5, xs: 3 }}
                        paddingY={{ lg: 0, xs: 4 }}
                        style={{ height: '100%' }}
                        textAlign={{ lg: textAlign, xs: textAlignMobile }}
                      >
                        <b2x.Div>
                          {doubleColumn.copy?.pretitle && (
                            <b2x.P className="pretitle" textAlign={{ lg: textAlign, xs: 'center' }}>
                              {b2x.formatHtml(doubleColumn.copy.pretitle)}
                            </b2x.P>
                          )}
                          <b2x.ConditionalWrapper
                            condition={doubleColumn.copy?.title !== undefined}
                            wrapper={
                              mainComponent ? (
                                <b2x.H1 className="title" textAlign={{ lg: textAlign, xs: 'center' }} />
                              ) : (
                                <b2x.P className="title" textAlign={{ lg: textAlign, xs: 'center' }} />
                              )
                            }
                          >
                            {b2x.formatHtml(doubleColumn.copy?.title)}
                          </b2x.ConditionalWrapper>
                          {doubleColumn.copy?.subtitle && (
                            <b2x.P className="subtitle">{b2x.formatHtml(doubleColumn.copy.subtitle)}</b2x.P>
                          )}
                          {doubleColumn.copy?.content && (
                            <b2x.Div className="content">{b2x.formatHtml(doubleColumn.copy.content)}</b2x.Div>
                          )}
                          {doubleColumn.copy?.cta && <b2x.CtaFromContent {...doubleColumn.copy.cta} />}
                        </b2x.Div>
                      </b2x.Div>
                    </b2x.Col>
                    {doubleColumn.asset?.type && (
                      <b2x.Col className="asset-column" size={{ lg: '', xs: 12 }}>
                        <b2x.ConditionalWrapper
                          condition={doubleColumn.copy?.cta !== undefined}
                          wrapper={
                            <b2x.router.Link
                              to={
                                doubleColumn.copy?.cta?.to?.href
                                  ? doubleColumn.copy.cta.to.href
                                  : doubleColumn.copy?.cta?.to?.code && getPagePath(doubleColumn.copy.cta.to.code)
                              }
                            />
                          }
                        >
                          <b2x.AssetV2 {...doubleColumn.asset} fluid />
                        </b2x.ConditionalWrapper>
                      </b2x.Col>
                    )}
                  </b2x.Row>
                </b2x.Div>
              </b2x.Div>
            );
          })}
        </b2x.Div>
      </Container>
    </b2x.Div>
  );
};
