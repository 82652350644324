import './AccountHomePageA.scss';

import { OrderApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import React from 'react';

import { useCustomerApi } from '../api/useCustomerApi';
import { useSessionApi } from '../api/useSessionApi';
import { useAppContext, useAppStaticContext } from '../AppContext';
import { Button } from '../Button';
import { Col } from '../Col';
import { appConfig } from '../config';
import { Container } from '../Container';
import { AccountHomePageAContentType, CtaContentSection } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { PageWithSubpageHeader } from '../PageWithSubpageHeader';
import { Link } from '../router/Link';
import { Row } from '../Row';
import { useMenu } from '../useMenu';
import { usePage } from '../usePage';
import { formatHtml, renderUI } from '../util';
import { AccountHomePageProps } from './AccountHomePage';

export const AccountHomePageA = (props: AccountHomePageProps) => {
  const page = usePage<AccountHomePageAContentType>({ populate: { content: true } });

  const menu = useMenu('MENU_ACCOUNT');

  const ordersMenuItem = React.useMemo(
    () => menu?.children[0].children.find((item) => item.code === 'SITE_CCN_ORDERS'),
    [menu?.children]
  );

  const helpMenuItem = React.useMemo(
    () => menu?.children[0].children.find((item) => item.code === 'SITE_CCN_HELP'),
    [menu?.children]
  );

  const infoMenuItem = React.useMemo(
    () => menu?.children[0].children.find((item) => item.code === 'SITE_CCN_INFO'),
    [menu?.children]
  );

  const wishlistMenuItem = React.useMemo(
    () => menu?.children[0].children.find((item) => item.code === 'SITE_CCN_WISHLIST'),
    [menu?.children]
  );

  const referralMenuItem = React.useMemo(
    () => menu?.children[0].children.find((item) => item.code === 'SITE_CCN_REFERRAL'),
    [menu?.children]
  );

  const couponMenuItem = React.useMemo(
    () => menu?.children[0].children.find((item) => item.code === 'SITE_CCN_COUPONS'),
    [menu?.children]
  );

  const creditCardsMenuItem = React.useMemo(
    () => menu?.children[0].children.find((item) => item.code === 'SITE_CCN_CREDIT_CARDS'),
    [menu?.children]
  );

  const privacyMenuItem = React.useMemo(
    () => menu?.children[0].children.find((item) => item.code === 'SITE_CCN_PRIVACY'),
    [menu?.children]
  );

  const taxBenefitsMenuItem = React.useMemo(
    () => menu?.children[0].children.find((item) => item.code === 'SITE_CCN_TAX_BENEFITS'),
    [menu?.children]
  );

  const { session } = useAppContext();
  const { logout } = useSessionApi();

  const [orders, setOrders] = React.useState<Array<OrderApiDto>>();

  const { getOrders } = useCustomerApi();

  React.useEffect(() => {
    if (session?.userLogged) {
      getOrders().then((response) => {
        setOrders(response.data);
      });
    }
  }, [getOrders, session?.userLogged]);

  return (
    <>
      <PageWithSubpageHeader
        pageSubtitle={t('account.welcomeMessage', { name: session?.customer?.name })}
        pageTitle={menu?.label}
        showPageTitle
      />
      {renderUI({
        bs5: (
          <Container>
            <Row className="justify-content-center">
              <Col size={{ xl: 9, xs: 12 }}>
                <Row className="mb-2 mb-md-4" gap={{ md: 4, xs: 2 }}>
                  <Col size={{ md: '', xs: 12 }}>
                    <div className="box-home-account-orders d-md-flex flex-column">
                      {ordersMenuItem?.link && ordersMenuItem.label && (
                        <BoxHomeAccount
                          alwaysCentred
                          icon={ordersMenuItem.iconName}
                          link={ordersMenuItem.link}
                          subtitle={ordersMenuItem.description}
                          title={ordersMenuItem.label}
                        />
                      )}
                      <div
                        className={classnames(
                          'orders-box bg-white text-center p-3 p-md-5 border-top border-lighter flex-grow-1',
                          {
                            'd-flex justify-content-center align-items-center': orders && orders.length > 0,
                          }
                        )}
                      >
                        {orders && orders.length > 0 ? (
                          ordersMenuItem?.link && (
                            <Link className="text-reset fw-bold" to={ordersMenuItem.link}>
                              {t('account.orders.trackOrdersState')}
                            </Link>
                          )
                        ) : (
                          <>
                            <div>{t('account.orders.noOrders')}</div>
                            <Link className={classnames('btn btn-primary mt-3 mt-md-5')} to="/search">
                              {t('misc.discoverOurProducts')}
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col size={{ md: '', xs: 12 }}>
                    <div
                      className="h-100 d-flex flex-column justify-content-end align-items-center"
                      style={{
                        backgroundImage: page?.content?.body.image ? `url(${page.content.body.image.url})` : 'none',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        minHeight: '350px',
                      }}
                    ></div>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4" gap={{ md: 4, xs: 2 }}>
                  {helpMenuItem && helpMenuItem.link && (
                    <Col size={12}>
                      <div className="help p-4 d-flex flex-column flex-md-row align-items-center bg-white">
                        <div className="text-center mb-4 mb-md-0 flex-grow-1">
                          <Link className="text-reset text-decoration-none" to={helpMenuItem.link}>
                            {helpMenuItem.iconName && <Icon className="mb-2" name={helpMenuItem.iconName} size={35} />}
                            <h6 className="fw-bold box-title">{t('customerCare.help')}</h6>
                          </Link>
                        </div>
                        <div className="text-center flex-grow-1">
                          <div className="mb-2">{t('customerCare.service')}</div>
                          <Link className="fw-bold" to={helpMenuItem.link}>
                            {helpMenuItem.label ?? t('customerCare.contact')}
                          </Link>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
                {page?.content?.body.boxesList && page.content.body.boxesList.length > 0 ? (
                  <Row className="mb-2 mb-md-4" gap={{ md: 4, xs: 2 }}>
                    {page.content.body.boxesList.map((box) => (
                      <div
                        className={classnames('col col-12', `col-md-${box.columnsNumber}`)}
                        key={box.contentSectionId}
                      >
                        <BoxHomeAccount
                          code={box.cta?.to?.code}
                          cta={box.cta}
                          icon={box.cta?.icon}
                          link={box.cta?.to?.href}
                          subtitle={box.subtitle}
                          title={box.cta?.label}
                        />
                      </div>
                    ))}
                  </Row>
                ) : (
                  <>
                    <Row className="mb-2 mb-md-4" gap={{ md: 4, xs: 2 }}>
                      {infoMenuItem && infoMenuItem.link && infoMenuItem.label && (
                        <Col size={{ sm: '', xs: 12 }}>
                          <BoxHomeAccount
                            icon={infoMenuItem.iconName}
                            link={infoMenuItem.link}
                            subtitle={infoMenuItem.description}
                            title={infoMenuItem.label}
                          />
                        </Col>
                      )}
                      {wishlistMenuItem && wishlistMenuItem.link && wishlistMenuItem.label && (
                        <Col size={{ sm: '', xs: 12 }}>
                          <BoxHomeAccount
                            icon={wishlistMenuItem.iconName}
                            link={wishlistMenuItem.link}
                            subtitle={wishlistMenuItem.description}
                            title={wishlistMenuItem.label}
                          />
                        </Col>
                      )}
                      {referralMenuItem && referralMenuItem.link && referralMenuItem.label && (
                        <Col size={{ sm: '', xs: 12 }}>
                          <BoxHomeAccount
                            icon={referralMenuItem.iconName}
                            link={referralMenuItem.link}
                            subtitle={referralMenuItem.description}
                            title={referralMenuItem.label}
                          />
                        </Col>
                      )}
                      {couponMenuItem && couponMenuItem.link && couponMenuItem.label && (
                        <Col size={{ sm: '', xs: 12 }}>
                          <BoxHomeAccount
                            icon={couponMenuItem.iconName}
                            link={couponMenuItem.link}
                            subtitle={couponMenuItem.description}
                            title={couponMenuItem.label}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row className="mb-2 mb-md-4" gap={{ md: 4, xs: 2 }}>
                      {creditCardsMenuItem && creditCardsMenuItem.link && creditCardsMenuItem.label && (
                        <Col size={{ sm: '', xs: 12 }}>
                          <BoxHomeAccount
                            icon={creditCardsMenuItem.iconName}
                            link={creditCardsMenuItem.link}
                            subtitle={creditCardsMenuItem.description}
                            title={creditCardsMenuItem.label}
                          />
                        </Col>
                      )}
                      {privacyMenuItem && privacyMenuItem.link && privacyMenuItem.label && (
                        <Col size={{ sm: '', xs: 12 }}>
                          <BoxHomeAccount
                            icon={privacyMenuItem.iconName}
                            link={privacyMenuItem.link}
                            subtitle={privacyMenuItem.description}
                            title={privacyMenuItem.label}
                          />
                        </Col>
                      )}
                      {taxBenefitsMenuItem && taxBenefitsMenuItem.link && taxBenefitsMenuItem.label && (
                        <Col size={{ sm: '', xs: 12 }}>
                          <BoxHomeAccount
                            icon={taxBenefitsMenuItem.iconName}
                            link={taxBenefitsMenuItem.link}
                            subtitle={taxBenefitsMenuItem.description}
                            title={taxBenefitsMenuItem.label}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                )}
                <Row className="mb-2 mb-md-4">
                  <Col className="text-end">
                    <Button
                      alignItems="center"
                      className="text-decoration-none"
                      iconStart={appConfig.icons?.logout ? { name: appConfig.icons.logout, size: 25 } : undefined}
                      label={t('account.logout')}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => {
                        logout();
                      }}
                      variant="blank"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        ),
      })}
    </>
  );
};

export interface BoxHomeAccountProps {
  alwaysCentred?: boolean;
  border?: boolean;
  children?: React.ReactNode;
  code?: string;
  cta?: CtaContentSection;
  icon?: string;
  link?: string;
  square?: boolean;
  subtitle?: string;
  title?: string;
}
export const BoxHomeAccount = ({
  alwaysCentred,
  border,
  code,
  icon,
  link,
  square,
  subtitle,
  title,
}: BoxHomeAccountProps) => {
  const { getPagePath } = useAppStaticContext();
  return renderUI({
    bs5: (
      <>
        <Link className="text-reset text-decoration-none" to={code ? getPagePath(code) : link}>
          <div
            className={classnames(
              'box-home-account h-100 bg-white px-3 px-md-4 px-lg-5 py-3 py-md-4 py-lg-5',
              { border: border },
              { square: square }
            )}
          >
            <div className="content">
              <div
                className={classnames(
                  'd-flex align-items-center',
                  { 'flex-column': alwaysCentred },
                  { 'flex-md-column': !alwaysCentred }
                )}
              >
                {icon && (
                  <Icon
                    className={classnames({ 'mb-3': alwaysCentred }, { 'ms-2 me-4 mb-md-3 mx-md-0': !alwaysCentred })}
                    name={icon}
                    size={35}
                  />
                )}
                <div
                  className={classnames(
                    'inner-content',
                    { 'text-center': alwaysCentred },
                    { 'text-md-center': !alwaysCentred }
                  )}
                  style={{ minWidth: '150px' }}
                >
                  {title && <h6 className={classnames('fw-bold box-title', { 'mb-0': !subtitle })}>{title}</h6>}
                  {subtitle && <div className="box-subtitle">{formatHtml(subtitle)}</div>}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </>
    ),
  });
};
