import { b2x } from '@b2x/react/src';

import { NewsletterPageContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';
import { Page } from './Page';

export interface NewsletterSubscribePageProps {}

export const NewsletterSubscribePage = () => {
  const content = b2x.useContent<NewsletterPageContentType>('NEWSLETTERSUBSCRIBE_PAGE_CONTENT');

  return (
    <Page noPaddingBottom noPaddingTop nudeBackground>
      <div className="container-fluid">
        <b2x.Row className="gx-0">
            <b2x.Col>
                <b2x.Row>
                    <b2x.Col className="px-0" size={{lg: 6, xl: 6, xs: 12}}>
                        <b2x.AssetV2 {...content?.body.asset} fluid />
                    </b2x.Col>
                    <b2x.Col className="p-3 p-lg-0" size={{lg: 6, xl: 6, xs: 12}}>
                      <div className="h-100 d-lg-flex align-items-center">
                          <div className="pe-lg-5">
                              <b2x.Row className="justify-content-center">
                                  <b2x.Col className="px-lg-5" size={{lg: 12, xl: 10, xs: 12 }}>
                                      <h1 className="h2 fw-medium pb-3">{b2x.formatHtml(content?.body.title)}</h1>
                                      <h6 className="pb-3">{b2x.formatHtml(content?.body.subTitle)}</h6>
                                      <div className="my-3">{b2x.formatHtml(content?.body.content)}</div>
                                      <NewsletterForm className="mb-3" emailFieldStyle="textInput" source="website-modal" />
                                  </b2x.Col>
                              </b2x.Row>
                          </div>
                      </div>
                    </b2x.Col>
                </b2x.Row>
            </b2x.Col>
        </b2x.Row>
      </div>
    </Page>
  );
};
