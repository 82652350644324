import { b2x } from '@b2x/react/src';
import React from 'react';

import { t } from '../i18n/i18n';

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesValidationSchema = b2x.yup.ObjectSchema<{}>;

export type NewsletterFormProps = Omit<
  b2x.NewsletterFormProps<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>,
  | 'additionalPropertiesInitialValues'
  | 'additionalProperties'
  | 'additionalPropertiesValidationSchema'
  | 'validationSchemaSelector'
>;

export const NewsletterForm = ({
  ctaContainerClassName,
  emailFieldStyle = 'textInput',
  ...otherProps
}: NewsletterFormProps) => {
  const { onSuccess } = otherProps;
  const onSuccessMemo = React.useCallback(() => {
    onSuccess && onSuccess();
    b2x.storage.setBoolean('newsletterConsent', true, true);
  }, [onSuccess]);

  return (
    <b2x.NewsletterFormHelper<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>
      {...otherProps}
      emailFieldStyle={emailFieldStyle}
      onSuccess={onSuccessMemo}
      redirectToHomeOnSuccessModalClose
      validationSchemaSelector={{ name: false, surname: false }}
    >
      {({ fieldsHelper, formik }) => (
        <>
          {emailFieldStyle === 'inputGroup' && (
            <b2x.FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
              <b2x.InputGroup {...fieldsHelper.email.inputGroup}>
                <b2x.TextInput className="me-3" {...fieldsHelper.email.textInput} />
              </b2x.InputGroup>
            </b2x.FormGroup>
          )}
          {emailFieldStyle === 'textInput' && (
            <b2x.FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
              <b2x.TextInput {...fieldsHelper.email.textInput} />
            </b2x.FormGroup>
          )}
          
          <b2x.FormGroup {...fieldsHelper.newsletterConsent.formGroup} label={undefined}>
            <b2x.Checkbox {...fieldsHelper.newsletterConsent.checkbox} labelClassName="extra-small text-start" />
          </b2x.FormGroup>
          <div className="d-lg-block d-grid">
            <b2x.Button
              {...fieldsHelper.buttons.submit}
              label={t('form.newsletterForm.buttons.submit.label')}
              variant="primary"
            />
          </div>
        </>
      )}
    </b2x.NewsletterFormHelper>
  );
};
