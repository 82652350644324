import { AddressApiDto, CartApiDto, CheckoutApiType } from '@b2x/storefront-api-js-client/src/dto';
import _ from 'lodash';
import React from 'react';

import { analytics } from './analytics/analytics';
import { useCartApi } from './api/useCartApi';
import { useCustomerApi } from './api/useCustomerApi';
import { useAppContext } from './AppContext';
import { appConfig } from './config';
import { InvoiceType } from './form/useAddressForm';
import { useSearchParams } from './router/router';
import { useNavigate } from './router/useNavigate';
import { storage } from './storage';

export type CheckoutStep =
  | 'email'
  | 'shippingProfile'
  | 'shippingAddress'
  | 'billingAddress'
  | 'paymentMethod'
  | 'summary';

export interface StepInfo {
  completed: boolean;
  current: boolean;
  editDisabled: boolean;
  step: CheckoutStep;
}

interface CheckoutContextInterface {
  addressBook?: Array<AddressApiDto>;
  canStartPayment: boolean;
  cart?: CartApiDto;
  deleteForcedStep(): void;
  invoiceType?: InvoiceType;
  sameAddresses: boolean;
  setForcedStep(step: CheckoutStep): void;
  setStepAlreadyDone(step: CheckoutStep): void;
  stepsInfo?: Array<StepInfo>;
}

// Purtroppo per un motivo a me sconosciuto, bisogna ricreare questa funzione qui, piuttosto che importala da util,
// altrimenti si rompe tutto.
const createContext = <T extends unknown | null>(displayName: string) => {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = displayName;
  const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined)
      throw new Error(`useContext must be inside a Provider with a value (missing: ${displayName})`);
    return context;
  };
  const useContextStrict = () => {
    const context = React.useContext(Context);
    return context;
  };
  return [Context.Provider, useContext, useContextStrict] as const;
};

export const [CheckoutContextProvider, useCheckoutContext, useCheckoutContextStrict] =
  createContext<CheckoutContextInterface>('CheckoutContext');

interface UseCheckoutContextInitializerProps {
  steps: Array<CheckoutStep>;
}

const useCheckoutContextInitializer = ({ steps }: UseCheckoutContextInitializerProps) => {
  const [cart, setCart] = React.useState<CartApiDto>();
  const [addressBook, setAddressBook] = React.useState<Array<AddressApiDto>>();

  const { getCart } = useCartApi();
  const { getAddresses } = useCustomerApi();
  const { session } = useAppContext();

  React.useEffect(() => {
    getCart({
      populate: {
        alerts: true,
        billingAddress: true,
        depositPayments: true,
        offers: true,
        packs: {
          alerts: true,
          cartSkus: {
            alerts: true,
            giftCard: true,
            offers: true,
            sku: {
              attributes: true,
              product: {
                attributes: true,
              },
            },
          },
        },
        paymentMethod: true,
        shippingAddress: true,
        shippingProfile: true,
      },
    }).then((response) => {
      setCart(response.data);
    });
  }, [getCart, session?.cart]);

  const navigate = useNavigate();

  // Se sono guest e non ho impostato email nel carrello, faccio redirect alla pagina whois.
  React.useEffect(() => {
    if (cart !== undefined && cart.guest && cart.email === undefined) {
      navigate('/whois');
    }
  }, [cart, navigate]);

  // // Se sono arrivato in qualche modo qui senza avere un carrello, torno al carrello.
  // React.useEffect(() => {
  //   if (cart === undefined) {
  //     navigate('/cart');
  //   }
  // }, [cart, navigate]);

  // Se sono arrivato in qualche modo qui senza aver superato gli alert bloccanti del carrello, torno al carrello.
  React.useEffect(() => {
    const cartBlockingAlerts = cart?.alerts?.filter((alert) =>
      appConfig.cartBlockingAlerts?.includes(alert.code as CheckoutApiType)
    );
    if (cartBlockingAlerts && cartBlockingAlerts.length > 0) {
      navigate('/cart');
    }
  }, [cart?.alerts, navigate]);

  const currentStep = React.useMemo<CheckoutStep | undefined>(
    () =>
      cart !== undefined
        ? steps.find(
            (step) =>
              (step === 'email' && cart.alerts?.some((alert) => alert.code === 'MISSING_EMAIL')) ||
              (step === 'billingAddress' &&
                (cart.alerts?.some((alert) => alert.code === 'MISSING_BILLING_ADDRESS') ||
                  !storage.getBoolean(`${cart.id}_billingAddress`))) ||
              (step === 'paymentMethod' &&
                (cart.alerts?.some((alert) => alert.code === 'MISSING_PAYMENT_METHOD') ||
                  !storage.getBoolean(`${cart.id}_paymentMethod`))) ||
              (step === 'shippingAddress' &&
                (cart.alerts?.some((alert) => alert.code === 'MISSING_SHIPPING_ADDRESS') ||
                  !storage.getBoolean(`${cart.id}_shippingAddress`))) ||
              (step === 'shippingProfile' && cart.alerts?.some((alert) => alert.code === 'MISSING_SHIPPING_PROFILE')) ||
              step === 'summary'
          )
        : undefined,
    [cart, steps]
  );

  const completedSteps = React.useMemo<Array<CheckoutStep> | undefined>(
    () => (currentStep !== undefined ? steps.slice(0, steps.indexOf(currentStep)) : undefined),
    [currentStep, steps]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const forcedStep = searchParams.get('step');

  const setForcedStep = React.useCallback(
    (step: CheckoutStep) => {
      searchParams.set('step', step);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const deleteForcedStep = React.useCallback(() => {
    searchParams.delete('step');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const stepsInfo = React.useMemo<Array<StepInfo> | undefined>(
    () =>
      completedSteps &&
      cart &&
      steps.map((step) => ({
        completed: completedSteps.includes(step),
        current: forcedStep ? step === forcedStep : step === currentStep,
        editDisabled: step === 'email' && !cart.guest,
        step: step,
      })),
    [cart, completedSteps, currentStep, forcedStep, steps]
  );

  // Se solo loggato ma non ho una mail impostata nel carrello, chiamo il servizio che automatizza tutta una serie di cose
  // React.useEffect(() => {
  //   if (!cart?.guest && cart?.email === undefined) {
  //     setDefaultCustomerData();
  //   }
  // }, [cart?.email, cart?.guest, setDefaultCustomerData]);

  // Solo se loggato, recupero la rubrica, e la aggiorno quando viene aggiornato il customer
  React.useEffect(() => {
    if (cart && !cart.guest) {
      getAddresses().then((response) => {
        setAddressBook(response.data);
      });
    }
  }, [cart, getAddresses, session?.customer]);

  // Tells if shippingAddress and BillingAddress are the same
  const sameAddresses = React.useMemo(() => {
    const {
      company: shippingAddressCompany,
      id: shippingAddressId,
      idAddressRif: shippingAddressIdAddressRif,
      pec: shippingAddressPec,
      taxCode: shippingAddressTaxCode,
      uniqueCode: shippingAddressUniqueCode,
      vatNumber: shippingAddressVatNumber,
      ...shippingAddressToCompare
    } = cart?.shippingAddress ?? {};
    const {
      company: billingAddressCompany,
      id: billingAddressId,
      idAddressRif: billingAddressIdAddressRif,
      pec: billingAddressPec,
      taxCode: billingAddressTaxCode,
      uniqueCode: billingAddressUniqueCode,
      vatNumber: billingAddressVatNumber,
      ...billingAddressToCompare
    } = cart?.billingAddress ?? {};
    return cart?.shippingAddress && cart.billingAddress
      ? _.isEqual(shippingAddressToCompare, billingAddressToCompare)
      : false;
  }, [cart?.billingAddress, cart?.shippingAddress]);

  //FIXME Da far arrivare dal back
  const invoiceType = React.useMemo<InvoiceType | undefined>(
    () =>
      cart?.billingAddress?.taxCode !== undefined &&
      cart.billingAddress.vatNumber !== undefined &&
      cart.billingAddress.company !== undefined &&
      cart.billingAddress.pec !== undefined &&
      cart.billingAddress.uniqueCode !== undefined
        ? 'company'
        : cart?.billingAddress?.taxCode !== undefined &&
          cart.billingAddress.vatNumber !== undefined &&
          cart.billingAddress.pec !== undefined &&
          cart.billingAddress.uniqueCode !== undefined
        ? 'freelance'
        : cart?.billingAddress?.taxCode !== undefined
        ? 'privatePerson'
        : undefined,
    [
      cart?.billingAddress?.company,
      cart?.billingAddress?.pec,
      cart?.billingAddress?.taxCode,
      cart?.billingAddress?.uniqueCode,
      cart?.billingAddress?.vatNumber,
    ]
  );

  const canStartPayment = React.useMemo<boolean>(
    () =>
      cart?.alerts?.filter((alert) => alert.severity !== 'INFO').length === 0 &&
      cart.packs !== undefined &&
      cart.packs.every(
        (pack) =>
          pack.alerts?.filter((alert) => alert.severity !== 'INFO').length === 0 &&
          pack.cartSkus !== undefined &&
          pack.cartSkus.every((cartSku) => cartSku.alerts?.filter((alert) => alert.severity !== 'INFO').length === 0)
      ),
    [cart?.alerts, cart?.packs]
  );

  console.clear();

  // Mando evento di "checkoutInit"
  const analyticsCheckoutInitEventSent = React.useRef<boolean>(false);
  const analyticsCheckoutAddShippingInfo = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (cart && !analyticsCheckoutInitEventSent.current) {
      analytics.events.checkoutInit('EVENT_ID', cart);
      analyticsCheckoutInitEventSent.current = true;
    }

    if (
      cart &&
      !analyticsCheckoutAddShippingInfo.current &&
      cart.shippingProfile &&
      stepsInfo?.find((e) => e.step === 'shippingAddress' && e.completed)
    ) {
      // FIX ME - ALESSANDRO
      // analytics.events.addShippingInfo('EVENT_ID', cart, 'SHIPPING_PRIFOLE');
      // analytics.events.addShippingInfo('EVENT_ID', cart, cart.shippingProfile);
      analyticsCheckoutAddShippingInfo.current = true;
    }
  }, [cart, stepsInfo]);

  const setStepAlreadyDone = React.useCallback(
    (step: CheckoutStep) => {
      cart && storage.setBoolean(`${cart.id}_${step}`, true);
    },
    [cart]
  );

  const checkoutContext: CheckoutContextInterface = React.useMemo(
    () => ({
      addressBook,
      canStartPayment,
      cart,
      deleteForcedStep,
      invoiceType,
      sameAddresses,
      setForcedStep,
      setStepAlreadyDone,
      stepsInfo,
    }),
    [
      addressBook,
      canStartPayment,
      cart,
      deleteForcedStep,
      invoiceType,
      sameAddresses,
      setForcedStep,
      setStepAlreadyDone,
      stepsInfo,
    ]
  );

  return {
    CheckoutContextProvider,
    checkoutContext,
  };
};

export interface CheckoutContextProps extends UseCheckoutContextInitializerProps {
  children: React.ReactNode | ((checkoutContext: CheckoutContextInterface) => React.ReactNode);
}

export const CheckoutContext = ({ children, ...otherProps }: CheckoutContextProps) => {
  const checkoutContextInitializer = useCheckoutContextInitializer(otherProps);
  return (
    <checkoutContextInitializer.CheckoutContextProvider value={checkoutContextInitializer.checkoutContext}>
      {typeof children === 'function' ? children(checkoutContextInitializer.checkoutContext) : children}
    </checkoutContextInitializer.CheckoutContextProvider>
  );
};
