import { b2x } from '@b2x/react/src';

import { NewsletterContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';

interface PopUpNewsletterModalProps extends b2x.ModalProps {}

const PopUpNewsletterModal = (modalProps: PopUpNewsletterModalProps) => {
  const content = b2x.useContent<NewsletterContentType>('NEWSLETTER_POPUP');

  return (
    <b2x.Modal {...modalProps} className="popup-newsletter-modal">
      <div style={{ background: `url(${content?.body.backgroundImage}` }}>
        <b2x.ModalHeader title="" />
        <b2x.ModalBody className="px-5 py-5">
          <div style={{ marginBottom: '5rem' }}>
            <div className="text-center fw-bold">{b2x.formatHtml(content?.body.title)}</div>
            <div className="text-center p-1 fw-medium">{b2x.formatHtml(content?.body.subTitle)}</div>
            <div className="text-center px-3 pt-2 pb-5">
              <p className="mt-3 fw-normal">{b2x.formatHtml(content?.body.content)}</p>
              <NewsletterForm
                ctaContainerClassName="text-center my-3"
                emailFieldStyle="textInput"
                source="website-modal"
              />
            </div>
          </div>
        </b2x.ModalBody>
      </div>
    </b2x.Modal>
  );
};

export const usePopUpNewsletterModal = (props?: PopUpNewsletterModalProps) =>
  b2x.useStaticModal(PopUpNewsletterModal, 'modal', props);
